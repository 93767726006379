export const countries = [
    'US', 'Canada', 'UK', 'Germany', 'Italy', 'Spain', 'France', 'Mexico',
    'Brazil', 'Argentina', 'Colombia', 'Russia', 'India', 'Indonesia', 'Chile',
    'Serbia', 'Croatia', 'Ireland', 'Australia', 'Romania', 'Czech Republic',
    'Austria', 'New Zealand', 'Kazakhstan', 'Iran', 'Portugal', 'UAE',
    'Slovakia', 'Egypt', 'Tunisia', 'Libya', 'Algeria', 'Turkey', 'Morocco',
    'Guatemala', 'Peru', 'Malaysia', 'South Africa', 'Ukraine', 'Moldova',
    'Poland', 'Netherlands', 'Georgia', 'Ecuador', 'Sri Lanka', 'Belize',
    'El Salvador', 'Honduras', 'Costa Rica', 'Nicaragua', 'Panama', 'Bermuda',
    'Bahamas', 'Haiti', 'Cuba', 'Jamaica', 'Dominican Republic', 'Dominica',
    'St. Kitts and Nevis', 'St. Lucia', 'St. Vincent and The Grenadines',
    'Barbados', 'Grenada', 'Trinidad and Tobago', 'Venezuela', 'Guyana',
    'Suriname', 'Bolivia', 'Paraguay', 'Uruguay', 'Iceland', 'Norway',
    'Sweden', 'Finland', 'Estonia', 'Latvia', 'Lithuania', 'Belarus',
    'Denmark', 'Andorra', 'Switzerland', 'Liechtenstein', 'Luxembourg',
    'Belgium', 'Malta', 'Bosnia and Herzegovina', 'Montenegro', 'Albania',
    'Macedonia', 'Greece', 'Bulgaria', 'Yemen', 'Oman', 'Qatar', 'Bahrain',
    'Saudi Arabia', 'Kuwait', 'Iraq', 'Jordan', 'Israel', 'Lebanon', 'Syria',
    'Cyprus', 'Armenia', 'Azerbaijan', 'Afghanistan', 'Pakistan',
    'Turkmenistan', 'Uzbekistan', 'Tajikistan', 'Kyrgyzstan', 'China',
    'Mongolia', 'Nepal', 'Maldives', 'Bhutan', 'Bangladesh', 'Myanmar',
    'Thailand', 'Laos', 'Cambodia', 'Vietnam', 'Singapore', 'Brunei',
    'East Timor', 'Philippines', 'South Korea', 'North Korea', 'Japan',
    'Mauritania', 'Mali', 'Senegal', 'Gambia', 'Guinea Bissau', 'Guinea',
    'Sierra Leone', 'Liberia', 'Ivory Coast', 'Ghana', 'Burkina', 'Togo',
    'Benin', 'Niger', 'Nigeria', 'Chad', 'Cameroon', 'Central African Republic',
    'North Sudan', 'South Sudan', 'Eritrea', 'Djibouti', 'Ethiopia',
    'Somalia', 'Equatorial Guinea', 'Gabon', 'Republic of Congo',
    'Democratic Republic of Congo', 'Uganda', 'Kenya', 'Rwanda', 'Burundi',
    'Tanzania', 'Angola', 'Zambia', 'Malawi', 'Mozambique', 'Namibia',
    'Botswana', 'Zimbabwe', 'Lesotho', 'Swaziland', 'Seychelles', 'Comoros',
    'Madagascar', 'Mauritius', 'Cape Verde'
];

export const genres = [
    "Techno (Melodic, Minimal)",
    "Techno (Hard, Peak)",
    "House (Tech House)",
    "House (Melodic, Afro)",
    "EDM",
    "D&B",
    "Bass",
    "Psy, Trance",
    "Dubstep"
];
